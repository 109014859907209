"use client";

export { default as Stepper, type StepperProps } from "@mui/material/Stepper";
export { default as Step, type StepProps } from "@mui/material/Step";
export {
  default as StepLabel,
  type StepLabelProps,
} from "@mui/material/StepLabel";
export {
  default as StepButton,
  type StepButtonProps,
} from "@mui/material/StepButton";
export {
  default as StepConnector,
  type StepConnectorProps,
} from "@mui/material/StepConnector";
export {
  default as StepContent,
  type StepContentProps,
} from "@mui/material/StepContent";
export {
  default as StepIcon,
  type StepIconProps,
} from "@mui/material/StepIcon";
export {
  default as MobileStepper,
  type MobileStepperProps,
} from "@mui/material/MobileStepper";
